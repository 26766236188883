// codepen.io/jude1/pen/rNgMzGW?editors=1100
// where is the analytic header information
// check there are h1 - 3 on each page

import React from "react";
// import Logo from "../img/logo.png";

export const MapContactCard = (props) => {
  const openMailto = () => {
    window.open(
      "mailto:arhiveorg@gmail.com?subject=Let%27s%20chat%20or%20meet%20up%20about%20a%20project&body=Thank%20you%20for%20your%20message.%20We%20will%20be%20in%20touch%20shortly.",
      "_blank"
    );
    window.location.href = "https://arhive.co"; // original page URL
  };

  return (
    <div className="Card__c">
      {/* <div className="CardP"> */}
      <div className="Card__text-container">
        {/* <div className="Card__logo">
          <img src={Logo} alt="Logo" />
        </div> */}
        {/* <h2 className="Contact__Heading">{props.Contact}</h2> */}
        {/* <h2 className="Card__text-Company">{props.Company}</h2> */}
        <h2 className="Card__text-Question">{props.Question}</h2>
        <h2 className="Card__text-Studio">{props.Studio}</h2>
        <h2 className="Card__text-Address">{props.Address}</h2>
        {/* <h2 className="Card__text-City">{props.City}</h2> */}
        <h2 className="Card__text-PostCode">{props.PostCode}</h2>
        {/* <p className="Card__text-ContactNumber">{props.ContactNumber}</p> */}
        {/* <p className="Card__text-Email">{props.Email}</p> */}
        <div className="Map__contact-button">
          <a
            href="mailto:arhiveorg@gmail.com?subject=Let's%20chat%20or%20meet%20up%20about%20a%20project&body=Thank%20you%20for%20your%20message.%20We%20will%20be%20in%20touch%20shortly."
            onClick={openMailto}
            className="contact-button"
          >
            -- Drop us a line --
          </a>
        </div>
      </div>
    </div>
    // </div>
  );
};
