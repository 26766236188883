// import React, { useState } from "react";
// import iconSQ from "../../img/Pages/SQ.png";
// import iconT5 from "../../img/Pages/T5.jpg";
// import iconXRH from "../../img/Pages/Xr-h.png";

// Import the Icons component
import Icons from "../../img/Icons";

// import ProjectsModal from "./ProjectsModal";
import { Countdown } from "../Countdown/Countdown";

import "./Projects.css";

export const Projects = () => {
  /* ProjectModal------------------
  const [showModal, setShowModal] = useState(false);
  const [modalLink, setModalLink] = useState("");

  const handleLoadClick = (externalLink) => {
    setShowModal(true);
    setModalLink(externalLink);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalLink("");
  };

  ---------------------------------*/

  const appData = [
    {
      imageUrl: Icons.iconSQ,
      title: "In Development",
      description:
        "Official soft launch November 2024 Jubilee Library, Brighton .",
      // externalLink: "https://streetquest.co/",
    },
    {
      imageUrl: Icons.iconT5,
      title: "Street Story",
      description:
        "Walking in the Past: An augmented reality touring experience around the city overlaying digital artefacts, revealing the hidden history about the city's involvement in the transatlantic slave trade.",
      externalLink: "https://streetstory.co/",
    },
    {
      imageUrl: Icons.iconXRH,
      title: "Xr-H",
      description:
        "An interactive heritage experience to facilitate discussion and creative heritage production, belonging, and place from the data of the Greater Brighton's area involvement in the transatlantic slave trade.",
      externalLink: "no link",
      // externalLink: "https://xr-h.netlify.app/",
    },
  ];

  return (
    <main>
      <section>
        <div className="Projects_page" id="Projects">
          <h1 className="Projects__mainTitle">Projects</h1>
          <Countdown />
          <div className="Projects__container">
            <div className="Grid__container-b">
              {appData.map((card, index) => (
                <article key={index} className="newsCard news-Slide-up">
                  <img
                    className="Projects__bg-img"
                    src={card.imageUrl}
                    alt={`Background ${index + 1}`}
                  />
                  <h3 className="newsCaption-header">{card.title}</h3>
                  <div className="newsCaption">
                    <p className="newsCaption-content">{card.description}</p>

                    <button
                      type="submit"
                      id="Project__submit-Btn-1"
                      className="Project-button"
                      // onClick={() => handleLoadClick(card.externalLink)}
                    >
                      LOAD
                    </button>
                  </div>
                </article>
              ))}
            </div>
          </div>
          {/* <ProjectsModal
        isOpen={showModal}
        onClose={closeModal}
        externalLink={modalLink}
      /> */}
        </div>
      </section>
    </main>
  );
};
