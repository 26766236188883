import * as React from "react";

import { HomePageCard } from "./HomePageCard";

import "./Home.css";

export const Home = () => {
  return (
    <div className="cardP">
      <HomePageCard
        CompanyName=<h1>AR HIVE</h1>
        HeadLine="Walk | Play | Discover"
        SubTitle="Building innovative storytelling experiences to achieve real-world measurable impact"
        Message=" [ Site currently in development ] "
      />
    </div>
  );
};
