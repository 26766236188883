import React, { useState } from "react";
import { Link } from "react-router-dom";

// import icons from icons
import Icons from "../img/Icons";

import "./Navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="main">
      {/* <img src={Icons.iconLogo} className="logo" alt="Logo" /> */}
      <div className="menu">
        <img
          src={Icons.iconTrigger}
          className={`menu__trigger ${isOpen ? "is-rotate" : ""}`}
          id="btn"
          onClick={toggleMenu}
          alt="Menu Trigger"
        />
        <Link to="/">
          <img
            src={Icons.iconHome}
            className={`menu__item menu__item--0 ${isOpen ? "item-0" : ""}`}
            alt="Home"
          />
        </Link>
        <Link to="/about">
          <img
            src={Icons.iconPerson}
            className={`menu__item menu__item--1 ${isOpen ? "item-1" : ""}`}
            alt="About"
          />
        </Link>
        <Link to="/projects">
          <img
            src={Icons.iconProject}
            className={`menu__item menu__item--2 ${isOpen ? "item-2" : ""}`}
            alt="Projects"
          />
        </Link>
        <Link to="/MapContact">
          <img
            src={Icons.iconEmail}
            className={`menu__item menu__item--3 ${isOpen ? "item-3" : ""}`}
            alt="Map & Contact"
          />
        </Link>
      </div>
    </div>
  );
};

// const Navbar = () => {
//   return (
//     <nav className="Primary__navbar">
//       <ul>
//         <li className="Primary__navbar-link">
//           <Link to="/">
//             <img className="Nav__icon" src={Icons.iconHome} alt="Home icon" />
//           </Link>

//           <Link to="/about">
//             <img
//               className="Nav__icon"
//               src={Icons.iconPerson}
//               alt="Person icon"
//             />
//           </Link>

//           <Link to="/mapData">
//             <img className="Nav__icon" src={Icons.iconMap} alt="Map icon" />
//           </Link>

//           {/* <Link to="/locations">
//             <img className="Nav__icon" src={Icons.iconEye} alt="Eye icon" />
//           </Link> */}

//           <Link to="/Gallery">
//             <img
//               className="Nav__icon"
//               src={Icons.iconCircles}
//               alt="circles icon"
//             />
//           </Link>

//           <Link to="/contact">
//             <img
//               className="Nav__icon"
//               src={Icons.iconEmail}
//               alt="Gallery icon"
//             />
//           </Link>
//         </li>
//       </ul>
//     </nav>
//   );
// };

export default Navbar;
