import React from "react";
import { Link } from "react-router-dom";
// import Logo from "../../img/Nav_Icons/logo.png";
import Icons from "../../img/Icons";
import "./Error.css";

export const Error = () => {
  return (
    <main className="main__body-error">
      <header className="main__error-header">
        <div className="card__logo-error">
          <img src={Icons.iconLogo} alt="AR Hive company logo" />
        </div>
        <h1 className="main__header-text"> 404</h1>
        <h1 className="main__body-text">Page not found </h1>
      </header>

      <Link className="main__error-link" to="/">
        back home
      </Link>
    </main>
  );
};
