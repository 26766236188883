import React from "react";

// Icons/images
import Icons from "../../img/Icons";

export const HomePageCard = (props) => {
  return (
    <div
      className="card-background"
      style={{ backgroundImage: `url(${Icons.iconBackground})` }}
    >
      <div>
        <div className="Card__text-container">
          <div className="Card__logo">
            <img src={Icons.iconLogo} alt="AR Hive logo" />
          </div>
          <h2 className="Card__text-projectName">{props.CompanyName}</h2>

          <p className="Card__headline">{props.HeadLine}</p>
          <p className="Card__subtitle">{props.SubTitle}</p>
          <p className="Card__message">{props.Message}</p>
          <div className="Card__Partners-container">
            <div className="Card__Partners">
              <img src={Icons.iconUKIE} alt="UKIE logo" />
              <img
                src={Icons.iconCA}
                alt="Brighton & Hove Cultural Alliance logo"
              />
              <img src={Icons.iconBH} alt="Brighton & Hove logo" />
              <img src={Icons.iconWIG} alt="Women in Games logo" />
              <img src={Icons.iconAC} alt="Arts Council logo" />
              <img src={Icons.iconLU} alt="Leveling up logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
