import Icons from "../../img/Icons";

export const AccordionData = [
  {
    title: "Purpose",
    content: `Our purpose is to drive impactful social change through inclusive transformative storytelling.`,
    src: Icons.iconQR,
    alt: "Mission image",
  },
  {
    title: "Vision",
    content: `We strive to be the global leader in playful world-changing immersive experiences, from mobile augmented reality to immersive virtual reality.`,
    src: Icons.iconJR,
    alt: "Vision image",
  },
  {
    title: "Services",
    content: `We leverage cutting-edge extended reality technologies, our services include immersive storytelling experiences, interactive AR/VR solutions, and with co-creation and collaborative workshops to create transformative conversations and drive positive change through participatory experiences`,
    src: Icons.iconOV,
    alt: "Services image ",
  },
  {
    title: "Team",
    content: `Our team of curious, creative innovators is united by a purposeful mission to inspire and enable personal growth. We bring together a range of cultural backgrounds and disciplines – from artists and designers to technologists and social impact strategists. This rich diversity of perspectives and expertise fuels our ability to tackle complex challenges and difficult world-changing questions in innovative ways.`,
    src: Icons.iconT,
    alt: "Team image",
  },
  // {
  //   title: "Why Walking",
  //   content: `stuff`,
  //   src: Icons.iconW,
  //   alt: "walking image",
  // },
];
