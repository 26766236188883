import React, { useEffect } from "react";
import "./Countdown.css";

// Icons/images
import Icons from "../../img/Icons";

export const Countdown = () => {
  useEffect(() => {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    // INSERT EVENT DATE AND TIME HERE IN THIS FORMAT: 'November 4, 2024, 10:00:00'
    const EVENTDATE = new Date("November 4, 2024, 10:00:00");

    const countDown = new Date(EVENTDATE).getTime();
    const x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDown - now;

      document.getElementById("days").textContent = Math.floor(distance / day);
      document.getElementById("hours").textContent = Math.floor(
        (distance % day) / hour
      );
      document.getElementById("minutes").textContent = Math.floor(
        (distance % hour) / minute
      );
      document.getElementById("seconds").textContent = Math.floor(
        (distance % minute) / second
      );

      // delay in milliseconds
    }, 0);

    return () => clearInterval(x);
  }, []);

  return (
    <div id="Countdown">
      <fieldset className="Countdown__form-body">
        {/* <div className="Grid__container-b"> */}
        <h1 className="Countdown__Heading">Time to Launch</h1>
        <div className="Countdown__img-container">
          <img
            className="bgd__image-Countdown"
            src={Icons.iconTeen3}
            alt="person in a vr headset"
          />
        </div>
        <div className="Countdown__Heading">
          <ul className="Countdown__components-container">
            <li className="Countdown__numbers">
              <span id="days">0</span>
              <span>DAYS</span>
            </li>
            <li className="Countdown__numbers">
              <span id="hours">0</span>
              <span>HOURS</span>
            </li>
            <li className="Countdown__numbers">
              <span id="minutes">0</span>
              <span>MINUTES</span>
            </li>
            <li className="Countdown__numbers">
              <span id="seconds">0</span>
              <span>SECONDS</span>
            </li>
          </ul>
        </div>
        <div className="Countdown__text">
          Countdown to the exhibition and launch is approaching! we're in
          development of our new game. Done miss out!
        </div>
        {/* </div> */}
      </fieldset>
    </div>
  );
};
