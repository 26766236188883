import React from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Marker } from "react-map-gl";
import { NavigationControl } from "react-map-gl";

import { MapContactCard } from "../MapContact/MapContactCard";

import Icons from "../../img/Icons";

import "./MapContact.css";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoianVkaXRocmlja2V0dHMiLCJhIjoiY2w1djA4Y2FuMDR6OTNibnAzbm1oeWN4dCJ9.bwVGnCWwW0QgGJp1KAoN2Q";

export const MapContact = () => {
  return (
    <div id="Contact">
      <div className="Grid__container-e">
        {/* Address */}
        {/* <div> */}
        <div className="cardP">
          <h1 className="Contact__Heading">Contact Us</h1>
          <MapContactCard
            // Company="AR Hive"
            Question="Have a question or a story to share? Send us a message or better yet, arrange an meeting to see us in person!"
            Studio=" Studio 1C2"
            Address=" Phoenix Art Studio, 10 - 14 Waterloo Place, Brighton & Hove"
            PostCode="BN2 9NB"
          />
        </div>
        {/* </div> */}

        {/* Map */}
        {/* <div className="Card__text-container-c"> */}
        <div className="cardP">
          <Map
            initialViewState={{
              longitude: -0.133833,
              latitude: 50.828929,
              zoom: 16,
            }}
            mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
            style={{ width: "100%", height: 600 }}
            // mapStyle="mapbox://styles/mapbox/dark-v11"
            mapStyle="mapbox://styles/mapbox/streets-v11"
          >
            <Marker
              longitude={-0.133833}
              latitude={50.828929}
              anchor="bottom"
            />
            <NavigationControl />
          </Map>
        </div>
        {/* </div> */}
      </div>

      <div id="Footer">
        <div className="Footer__container ">
          <ul className="Footer__ui">
            <a
              href="https://www.instagram.com/lovespictures__/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="Footer__icon"
                src={Icons.iconInstagram}
                alt="Instagram logo"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/judericketts/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="Footer__icon"
                src={Icons.iconLinkedin}
                alt="Linkedin logo"
              />
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};
