// 3D games icons
import iconAP from "./3D_Icons/AP.png";
import iconAW from "./3D_Icons/AW.png";
import iconCS from "./3D_Icons/CS.png";
import iconFA from "./3D_Icons/FA.png";

// Navigation icons
import iconTrigger from "./Nav_Icons/Trigger.svg";
import iconHome from "./Nav_Icons/home.svg";
import iconPerson from "./Nav_Icons/person.svg";
import iconMap from "./Nav_Icons/map.svg";
import iconCircles from "./Nav_Icons/circles.svg";
import iconEmail from "./Nav_Icons/atSign.svg";
import iconLogo from "./Nav_Icons/logo.png";
import iconGrid from "./Nav_Icons/grid.png";
import iconProject from "./Nav_Icons/project.svg";

//Pages Images
import iconQR from "./Pages/QR.png";
import iconBackground from "./Pages/SQ_BannerMap_.png";
//vision
import iconJR from "./Pages/JR.png";
//services
import iconOV from "./Pages/OV.png";
//team
import iconT from "./Pages/T.png";
//countdown
import iconTeen3 from "./Pages/Teen3.jpg";

//Project Images
// StreetQuest
import iconSQ from "./Pages/SQ.png";
//Street Story
import iconT5 from "./Pages/T5.jpg";
//Xr-h
import iconXRH from "./Pages/Xr-h.png";

//Partner Icons
import iconUKIE from "./Partner_Icons/UKIE.png";
import iconCA from "./Partner_Icons/CA.png";
import iconBH from "./Partner_Icons/BH.png";
import iconWIG from "./Partner_Icons/WIG.png";
import iconAC from "./Partner_Icons/AC.png";
import iconLU from "./Partner_Icons/LU.png";

//Socials
import iconInstagram from "./Social_Icons/instagram.svg";
import iconLinkedin from "./Social_Icons/linkedin.svg";
import iconVimeo from "./Social_Icons/vimeo.svg";
// import iconTwitter from "./twitter.svg";

//Team

const Icons = {
  iconUKIE,
  iconCA,
  iconBH,
  iconWIG,
  iconAC,
  iconLU,
  iconInstagram,
  iconLinkedin,
  iconAP,
  iconAW,
  iconCS,
  iconFA,
  // iconTwitter,
  iconLogo,
  iconVimeo,
  iconHome,
  iconPerson,
  iconMap,
  iconCircles,
  iconEmail,
  iconQR,
  iconTrigger,
  iconGrid,
  iconProject,
  iconBackground,
  iconSQ,
  iconT5,
  iconXRH,
  iconJR,
  iconOV,
  iconT,
  iconTeen3,
};

export default Icons;
